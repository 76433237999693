export const hkCmeraManageColumns = () => {
  return [
    {
      title: '序号',
      width: '70px',
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '所属部门',
      align: 'center',
      dataIndex: 'department.name',
    },
    {
      title: '所属设备',
      align: 'center',
      dataIndex: 'instanceName',
    },
    {
      title: '安装地址',
      align: 'center',
      dataIndex: 'location',
    },
    {
      title: '监控名称',
      align: 'center',
      dataIndex: 'cameraName',
    },
    {
      title: '网关服务器（host）',
      align: 'center',
      dataIndex: 'host',
    },
    {
      title: '监控类型',
      align: 'center',
      dataIndex: 'cameraTypeName',
    },
    {
      title: '操作',
      align: 'center',
      fixed: 'right',
      width: '240px',
      scopedSlots: { customRender: 'action' },
    },
  ]
}
export const cameraFormVO = () => {
  return {
    host: '',
    appKey: '',
    department: {
      id: null,
    },
    appSecret: '',
    url: '',
    systemModule: 'monitoring_point',
    instanceId: null,
    instanceName: '',
    cameraIndexCode: '',
    cameraName: '',
    cameraType: '',
    cameraTypeName: '',
    location: '',
  }
}
export const syncCameraFormVO = () => {
  return {
    host: '',
    appKey: '',
    appSecret: '',
    departmentId: null,
    url: '',
  }
}

/*
export const overlayTypeList = () => {
  return [
    { value: 'marker', label: '点' },
    { value: 'polyline', label: '线' },
    { value: 'polygon', label: '面' }
  ]
}*/

// 设备列表表头
export const deviceColumns = () => {
  return [
    {
      title: '序号',
      width: '70',
      scopedSlots: { customRender: '_index' },
      align: 'center',
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '设备编号',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
    },
    {
      title: '设备类型',
      dataIndex: 'category.name',
      align: 'center',
    },
    {
      title: '设备所属部门',
      dataIndex: 'department.name',
      align: 'center',
    },
    {
      title: '存放仓库',
      dataIndex: 'depot.name',
      align: 'center',
    },
    {
      title: '操作',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center',
    },
  ]
}
export const moduleType = function () {
  return [
    { value: 'device_info', label: '在线设备' },
    { value: 'facility_info', label: '设施' },
    { value: 'device_depot', label: '仓库' },
  ]
}
// 设施列表表头
export const facilityColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '设施名称',
      width: 200,
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '设施类型',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'facilityType' },
    },
    {
      title: '所属部门',
      width: 200,
      align: 'center',
      dataIndex: 'department.name',
    },
    {
      title: '所在位置',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'location' },
    },
    {
      title: '标绘类型',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'plotType' },
    },
    {
      title: '备注',
      width: 200,
      align: 'center',
      dataIndex: 'remarks',
    },
    {
      title: '操作',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
}
// 仓库列表表头
export const depotColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '仓库名称',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'name' },
    },
    {
      title: '所属部门',
      width: 200,
      align: 'center',
      dataIndex: 'department.name',
    },
    {
      title: '状态',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'status' },
    },
    {
      title: '所在位置',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'location' },
    },
    {
      title: '操作',
      width: 200,
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
}
// 仓库状态
export const statusList = function () {
  return [
    { value: 'START', label: '启用' },
    { value: 'STOP', label: '停用' },
  ]
}

// 监控类型
export const cameraTypeSelect = function () {
  return [
    { value: '0', label: '枪机' },
    { value: '1', label: '半球' },
    { value: '2', label: '快球' },
    { value: '3', label: '云台枪机' },
  ]
}
