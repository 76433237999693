<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">视频监控管理</h1>
    </div>
    <div slot="extra">
      <a-button type="link" class="m-l-6" @click="testView">例：视频播放(单个)</a-button>
      <a-button type="link" class="m-l-6" @click="testView2">例：视频播放(多个)</a-button>
      <a-button @click="handleAdd" icon="sync" type="primary">同步监控信息</a-button>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="监控名称">
              <a-input v-model="searchParams.name" @change="search" placeholder="请输入监控名称" />
            </a-form-item>
            <a-form-item label="安装地址">
              <a-input v-model="searchParams.location" @change="search" placeholder="请输入安装地址" />
            </a-form-item>
            <a-form-item label="所属部门">
              <a-tree-select
                style="width: 210px;"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="orgTree"
                @change="search"
                allowClear
                placeholder="请选择所属部门"
                v-model="searchParams.departmentId"
              ></a-tree-select>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" class="m-l-6" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :rowKey="(record) => record.id"
        :pagination="pagination"
        @change="tableChange"
        :columns="columns"
        :dataSource="tableData"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button type="primary" @click="handleView(record)" shape="circle" icon="eye" size="small"></a-button>
          <a-divider type="vertical" />
          <a-button type="primary" shape="circle" icon="edit" @click="handleEdit(record.id)" size="small"></a-button>
          <a-divider type="vertical" />
          <a-button
            type="primary"
            @click="handleSingleSync(record.id)"
            shape="circle"
            icon="redo"
            size="small"
          ></a-button>
          <a-divider type="vertical" />
          <a-button type="danger" @click="handleDelete(record.id)" shape="circle" icon="delete" size="small"></a-button>
        </template>
      </a-table>
    </a-card>
    <a-modal
      title="同步权限下所有设备"
      :visible="addModal"
      :maskClosable="false"
      @ok="handleAddOk"
      @cancel="closeAddModal"
    >
      <sync-form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></sync-form>
    </a-modal>
    <a-modal
      :title="'视频监控-' + modalTitle"
      @cancel="detailClose"
      :maskClosable="false"
      width="1048px"
      :footer="null"
      :visible="detailDrawer"
    >
      <h-k-player ref="hkPlayerRef" :cameraIndexCodes="testCameraIndexCodes"></h-k-player>
    </a-modal>
    <a-modal
      title="编辑监控信息"
      :visible="editModal"
      :maskClosable="false"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading"
    >
      <Form ref="editRef" @editSuccess="editSuccess" @editError="editError"></Form>
    </a-modal>
    <a-modal
      :footer="null"
      :destroyOnClose="true"
      title="监测点"
      :visible="visible"
      @cancel="handCancel"
    >
      <monitoringPointTree @selectedTreeNode="selectedTreeNode" :detail="false"></monitoringPointTree>
    </a-modal>
  </page-layout>
</template>

<script>
import entityCRUD from '../common/mixins/entityCRUD'
import SERVICE_URLS from '@/api/service.url'
import PageLayout from '@/components/page/PageLayout'
import { hkCmeraManageColumns } from './common/common'
import Form from './Form'
import SyncForm from './SyncForm'
import HKPlayer from './HKPlayer'
import monitoringPointTree from '@/components/monitoringPointTree/List'

export default {
  name: 'List',
  mixins: [entityCRUD],
  components: { PageLayout, Form, SyncForm, HKPlayer, monitoringPointTree },
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.hkInfo,
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        departmentId: null,
        status: '',
        type: '',
        cameraIndexCode: '',
        cameraName: '',
        appSecret: '',
        channelNo: '',
        name: '',
        instanceId: null,
        instanceName: '',
        location: '',
      },
      orgTree: [],
      initColumns: hkCmeraManageColumns(),
      chooseItem: {},
      testCameraIndexCodes: [],
      visible: false,
      modalTitle: '暂未绑定设备'
    }
  },
  created() {
    this.getOrgTree()
  },
  methods: {
    getOrgTree() {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        noTips: true,
        success: (data) => {
          this.orgTree = data.body
        },
      })
    },
    handleView(record) {
      this.modalTitle = record.instanceName ? record.instanceName : '暂未绑定设备'
      this.testCameraIndexCodes = []
      this.detailDrawer = true
      this.chooseItem = record
      this.testCameraIndexCodes.push(record.cameraIndexCode)
      this.$nextTick(() => {
        this.$refs.hkPlayerRef.play()
      })
    },
    handleSingleSync(id) {
      this.$http(this, {
        url: SERVICE_URLS.hkInfo.synchronous_device,
        noTips: false,
        params: {
          id: id,
        },
        successTipsContent: '同步单个设备信息成功!',
        success: (data) => {
          this.search()
        },
        error: (data) => {},
      })
    },
    detailClose() {
      this.detailDrawer = false
      this.$refs.hkPlayerRef.closeHandle()
    },
    testView() {
      this.testCameraIndexCodes = ['3dc1e3e679d340e8afbe020b67e09da0']
      this.detailDrawer = true
      this.$nextTick(() => {
        this.$refs.hkPlayerRef.play()
      })
    },
    testView2() {
      this.testCameraIndexCodes = ['3dc1e3e679d340e8afbe020b67e09da0', '5b5298f6bbb449aa835051559d0567af']
      this.detailDrawer = true
      this.$nextTick(() => {
        this.$refs.hkPlayerRef.play()
      })
    },
    clickMonitoringPoint () {
      this.visible = true
    },
    handCancel () {
      this.visible = false
    },
    selectedTreeNode (key, node) {
      this.visible = false
      this.selectData = node.dataRef
      this.searchParams.instanceId = this.selectData.key
      this.searchParams.instanceName = this.selectData.title
      this.search()
    },
  },
}
</script>

<style scoped></style>
