<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="主机(host)" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="[
            'host',
            { rules: [{ required: true, message: '主机不能为空' }], initialValue: '202.100.100.50:4443' },
          ]"
          placeholder="请输入主机地址"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="秘钥(appKey)" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="['appKey', { rules: [{ required: true, message: '秘钥不能为空' }], initialValue: '27339437' }]"
          placeholder="请输入秘钥"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="秘钥(appSecret)" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="[
            'appSecret',
            { rules: [{ required: true, message: '秘钥不能为空' }], initialValue: 'zATVBCzPOAAiHtnWBzAz' },
          ]"
          placeholder="请输入秘钥"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="唯一标识" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="['cameraIndexCode', { rules: [{ required: true, message: '唯一标识不能为空' }] }]"
          placeholder="请输入唯一标识"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="监控名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="['cameraName', { rules: [{ required: true, message: '监控名称不能为空' }] }]"
          placeholder="请输入监控名称"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="监控类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select
          style="width: 100%;"
          placeholder="请选择监控类型"
          @change="handleCameraType"
          v-decorator="['cameraType', { rules: [{ required: true, message: '监控类型不能为空' }] }]"
        >
          <a-select-option v-for="item in cameraTypeList" :key="item.value" :value="item.value" :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['departmentId', { rules: [{ required: true, message: '部门不能为空' }], initialValue: orgId }]"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgTree"
          placeholder="请选择部门"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="绑定设备" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input-group>
          <a-row :gutter="8">
            <a-col :span="18">
              <a-input placeholder="请选择设备" v-decorator="['instanceName']" :disabled="true"></a-input>
            </a-col>
            <a-col :span="3">
              <a-button type="primary" icon="plus" @click="clickMonitoringPoint"></a-button>
            </a-col>
            <a-col :span="3">
              <a-button type="primary" icon="delete" @click="clearInstance"></a-button>
            </a-col>
          </a-row>
        </a-input-group>
        <a-modal
          :footer="null"
          :destroyOnClose="true"
          title="设备"
          :visible="visible"
          @cancel="handCancel"
        >
          <monitoringPointTree @selectedTreeNode="selectedTreeNode" @selectTreeNodeDetail="selectTreeNodeDetail"></monitoringPointTree>
        </a-modal>
      </a-form-item>
      <a-form-item label="安装地址" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="['location']"
          placeholder="请输入安装地址"
        >
        </a-input>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { cameraFormVO, cameraTypeSelect } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import monitoringPointTree from '@/components/monitoringPointTree/List'

  export default {
    components: { monitoringPointTree },
    name: 'Form',
    data () {
      return {
        labelCol: { span: 7 },
        wrapperCol: { span: 15 },
        orgTree: [],
        spinShow: false,
        formItem: cameraFormVO(),
        form: this.$form.createForm(this),
        orgId: null,
        instanceId: null,
        showFormItem: false,
        cameraTypeList: cameraTypeSelect(),
        selectData: null,
        visible: false
      }
    },
    created () {
      this.getOrgTree()
      this.defaultOrg()
    },
    mounted () {
    },
    methods: {
      loadData (id) {
        this.$http(this, {
          url: SERVICE_URLS.hkInfo.view,
          params: { id },
          noTips: true,
          success: (data) => {
            this.setFields(data.body)
            this.instanceId = data.body.instanceId
          }
        })
      },
      defaultOrg () {
        this.$nextTick(() => {
          const organizationJobs = this.$store.getters.currentUser.organizationJobs
          organizationJobs.forEach((ele) => {
            if (ele.ifDefault) {
              this.orgId = ele.organizationId + ''
            }
          })
        })
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      setVOFields (values) {
        Object.assign(this.formItem, values)
        this.formItem.systemModule = 'monitoring_point'
        this.formItem.department.id = values.departmentId
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          host: this.formItem.host,
          appKey: this.formItem.appKey,
          departmentId: this.formItem.department.id + '',
          appSecret: this.formItem.appSecret,
          // instanceId: this.formItem.instanceId,
          instanceName: this.formItem.instanceName,
          cameraIndexCode: this.formItem.cameraIndexCode,
          cameraName: this.formItem.cameraName,
          cameraType: this.formItem.cameraType,
          location: this.formItem.location,
        })
      },
      submitAdd () {
        this.spinShow = true
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.hkInfo.synchronous_devices,
              data: this.formItem,
              success: (data) => {
                this.formItem = cameraFormVO()
                this.$emit('addSuccess')
                this.form.resetFields()
                this.spinShow = false
              },
              error: (data) => {
                this.$emit('addError')
                this.form.resetFields()
                this.spinShow = false
              }
            })
          } else {
            this.spinShow = false
          }
        })
      },
      submitEdit () {
        this.spinShow = true
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.hkInfo.update,
              data: this.formItem,
              params: {
                id: this.formItem.id
              },
              success: (data) => {
                this.$emit('editSuccess')
                this.spinShow = false
              },
              error: (data) => {
                this.$emit('editError')
                this.spinShow = false
              }
            })
          } else {
            this.spinShow = false
          }
        })
      },
      resetInstance () {
        this.form.setFieldsValue({
          instanceName: '',
          instanceId: null
        })
      },
      handleCameraType (value) {
        this.cameraTypeList.forEach((item) => {
          if (item.value === value) {
            this.formItem.cameraTypeName = item.label
          }
        })
      },
      clickMonitoringPoint () {
        this.visible = true
      },
      handCancel () {
        this.visible = false
      },
      selectedTreeNode (key, node) {
        this.selectData = node.dataRef
        this.formItem.instanceId = this.selectData.key
        this.form.setFieldsValue({
          'instanceName': this.selectData.title,
        })
      },
      selectTreeNodeDetail (detail) {
        this.form.setFieldsValue({
          'departmentId': detail.orgId ? detail.orgId : '',
        })
        this.visible = false
      },
      clearInstance () {
        this.form.setFieldsValue({
          'instanceName': '',
        })
        this.formItem.instanceId = null
      }
    }
  }
</script>

<style scoped></style>
